/* eslint-disable */

import { pinFileToIPFS } from "./pinata.js";
require("dotenv").config();
const alchemyKey = process.env.REACT_APP_ALCHEMY_KEY_PF;
const publicKey = process.env.REACT_APP_PUBLIC_KEY_PF; 
const baseURL = process.env.REACT_APP_BASE_URL;
const uploadPath = process.env.REACT_APP_UPLOAD_PATH;
const pkey = process.env.REACT_APP_PINATA_KEY;
const psecret = process.env.REACT_APP_PINATA_SECRET;
const jsonPath = process.env.REACT_APP_UPLOAD_JSON_PATH;
const nftName = process.env.REACT_APP_NFT_NAME;
const nftDescription = process.env.REACT_APP_NFT_DESCRIPTION;
const nftFolderName = process.env.REACT_APP_NFT_FOLDERNAME;
const nftTotalSupply = process.env.REACT_APP_NFT_TOTAL_SUPPLY;
 
const FormData = require('form-data');
const contractABI = require("../contract-abi-pf.json");

const alchemyKeyPF = process.env.REACT_APP_ALCHEMY_KEY_PF;
const publicKeyPF = process.env.REACT_APP_PUBLIC_KEY_PF; 

const fs = require('fs')
const jquery = require('jquery')
 
const contractAddress = "0x25171B354F3e192Ea4fB0c1268255D7A64448C91"; //production -tt
 
const { createAlchemyWeb3 } = require("@alch/alchemy-web3");
const web3 = createAlchemyWeb3(alchemyKey);

export const getOwnedTokens = async() => {

    var tokenInst = await new web3.eth.Contract(contractABI, contractAddress);
   
    tokenInst.methods.balanceOf(window.ethereum.selectedAddress).call().then(function (bal) {
       // console.log(bal);

       for(var i = 0; i < bal.toNumber(); i++) {
           tokenInst.tokenOfOwnerByIndex.call(window.ethereum.selectedAddress, i)
           .then((id) => { 
       
              // console.log("I own token ID:" + id.tostring())
           });       
       }   
 
    })

    /*for(var i = 0; i < balance.toNumber(); i++) {
        myERC721Token.tokenOfOwnerByIndex.call(web3.eth.accounts[0], i)
        .then((id) => { ... });       
    }   
    */

}


function dataURItoBlob(dataURI) {
    // convert base64/URLEncoded data component to raw binary data held in a string
    var byteString;

    if (dataURI.split(',')[0].indexOf('base64') >= 0)
        byteString = atob(dataURI.split(',')[1]);
    else
        byteString = unescape(dataURI.split(',')[1]);

    // separate out the mime component
    var mimeString = dataURI.split(',')[0].split(':')[1].split(';')[0];

    // write the bytes of the string to a typed array
    var ia = new Uint8Array(byteString.length);
    for (var i = 0; i < byteString.length; i++) {
        ia[i] = byteString.charCodeAt(i);
    }

    return new Blob([ia], { type: mimeString });
}


export const connectWallet = async () => {
    if (window.ethereum) {

         //const networkId = await web3.eth.net.getId();
        //const networkType = await web3.eth.net.getNetworkType();

       // console.log(networkId)
       // console.log(networkType)

    try {
      const addressArray = await window.ethereum.request({
        method: "eth_requestAccounts",
      });
      const obj = {
         // status: "👆🏽 Write a message in the text-field above.",
          status: "connected",
        address: addressArray[0],
      };
      return obj;
    } catch (err) {
      return {
        address: "",
        status: "😥 " + err.message,
      };
    }
  } else {
    return {
      address: "",
      status: (
        <span>
          <p>
            {" "}
            🦊{" "}
            <a target="_blank" href={'https://metamask.io/download.html'}>
              You must install Metamask, a virtual Ethereum wallet, in your
              browser.
            </a>
          </p>
        </span>
      ),
    };
  }
};

export const getCurrentWalletConnected = async () => {
    if (window.ethereum) {

        const networkId = await web3.eth.net.getId();
        const networkType = await web3.eth.net.getNetworkType();

        //console.log(networkId)
        //console.log(networkType)
    try {
      const addressArray = await window.ethereum.request({
        method: "eth_accounts",
      });
        if (addressArray.length > 0) {

            //showm mint button here

        return {
          address: addressArray[0],
          status:"connected",
        };
      } else {
        return {
          address: "",
          status: "Connect to Metamask using the top right button.",
        };
      }
    } catch (err) {
      return {
        address: "",
        status: "😥 " + err.message,
      };
    }
  } else {
    return {
      address: "",
      status: (
        <span>
          <p>
            {" "}
            🦊{" "}
            <a target="_blank" href={`https://metamask.io/download.html`}>
              You must install Metamask, a virtual Ethereum wallet, in your
              browser.
            </a>
          </p>
        </span>
      ),
    };
  }
};

async function loadContract() {
  return new web3.eth.Contract(contractABI, contractAddress);
}
 
 
async function writeIfMissing(id){
    //check if file exists
    try {
        const file = `./sold_tokens/${id}`;
        const final_file = `./final_metadata/${id}`;
        // file missing
        if (!fs.existsSync(file) && fs.existsSync(final_file)) {
            try {
                await this.logTransfer(id);
            } catch (err) {
                console.log(err);
            }
        }
    } catch(err) {
        console.error(err)
    }
}
 



async function writeFile(id,sNFTMetaFileName, jsonString){
    //check if file exists
    try {
      fs.writeFile(sNFTMetaFileName, jsonString, err => {
        if (err) {
            console.log('Error writing file', err)
        } else {
            console.log('Successfully wrote file')

                //now mint 

            }
        })
    } catch(err) {
        console.error(err)
    }
}
async function logTransfer(id) {
    try {
        var oldPath = `./final_metadata/${id}`;
        var newPath = `./sold_tokens/${id}`;

        fs.copyFile(oldPath, newPath, function (err) {
            if (err) throw err
        })
    } catch (err) {
        console.log(err);
    }
}


var saveNote = function(note) {
     

    
};


export const getTotalContractSupply = async () => {

    window.contract = await new web3.eth.Contract(contractABI, contractAddress);
    const nftContract = window.contract; 
    const sTotalSupply = await nftContract.methods.totalSupply().call()
    const nNftTotalSupply = parseInt(nftTotalSupply);
   
    return {
        TotalSupply: nNftTotalSupply 
    };
}
 
//clean code function
export const mintPixelFoxesNFT = async (url, name, description, mintNumber) => {

    const nMintNumber = mintNumber;
    
    if (nMintNumber > 30) { 
        return {
            success: false,
            status: "Mint cannot exceed 30",
        }; 
    }
    
    window.contract = await new web3.eth.Contract(contractABI, contractAddress);

    const nftContractPF = window.contract;
    
    const blnPausedPF = await nftContractPF.methods._paused.call().call();
    
    if (blnPausedPF) {
        console.log("return");
        return {
            success: false,
            status: "Minting not active.",
        };
    }
    
    const sTotalSupply = await nftContractPF.methods.totalSupply().call()
    
   // const nNftTotalSupply = parseInt(nftTotalSupply);

    //if (sTotalSupply >= nNftTotalSupply) {
    if (sTotalSupply >= 10000) {
        return {
            success: false,
            status: "Premint Sold Out!",
        };
    }

    const sPrice = await nftContractPF.methods.getPrice().call()
    const amountToSend = 10000000000000000// 10000000000000000;.01 eth
    const nonce = await web3.eth.getTransactionCount(window.ethereum.selectedAddress, 'latest'); //get latest nonce
    const ethAddress = window.ethereum.selectedAddress;
    const sGasPrice = web3.utils.toHex(await web3.eth.getGasPrice());

    const sMintPrice = (nMintNumber * 0.01)
    //the transaction
    const tx = {
        from: ethAddress,
        to: contractAddress,
        gasPrice: sGasPrice,
        value: web3.utils.toHex(web3.utils.toWei(String(sMintPrice), 'ether')),
        data: nftContractPF.methods.FoxMint(web3.utils.toBN(nMintNumber)).encodeABI()
    };

    try {

        const txHash = await window.ethereum.request({
            method: "eth_sendTransaction",
            params: [tx],
        });

        return {
            success: true,
            status:
            "✅ Successful transaction. Check on Etherscan: https://etherscan.io/tx/" +
            txHash,
        };

    } catch (error) {

        return {
            success: false,
            status: "Something went wrong: " + error.message,
        };

    }

};
