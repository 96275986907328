/* eslint-disable */
import { useEffect, useState } from "react";
import {
    connectWallet,
    getCurrentWalletConnected,
    mintLCCNFT,  
} from "../util/interact_SadClownParty.js";
import Swal from 'sweetalert2'

import ReactBootstrapSlider from 'react-bootstrap-slider';
import "../css/bootstrap.css";
import "../css/bootstrap-slider.css"
 
const SliderDiv = (props) => {
     
    return (
        <div>
            
            <input id="val" type="text"></input>
        </div>
        
        );
};

const SadClownParty = (props) => {
    
    const [walletAddress, setWallet] = useState("");
    const [status, setStatus] = useState("");

    const [name, setName] = useState("");
    const [description, setDescription] = useState("");
    const [url, setURL] = useState("");
    const [mintNumber, setMintNumber] = useState("");

    useEffect(async () => {
        const { address, status } = await getCurrentWalletConnected();

        setWallet(address);

        if (status == "connected") {
            setStatus(<p>
                {" "}
                {" "}

                <div className="row rowMint">
                    <div className="Minter">
                        <div className="col-9" style={{ float: "left" }}>
                            <ReactBootstrapSlider
                                value={1}
                                change={changeValue}
                            step={1}
                            max={30}
                            min={1}
                            orientation="horizontal" />
                         </div>
                        <div className="col-3" style={{position: "relative", top: "-4px", float: "left" }}>
                            <input id="txtMintNumber" style={{
                                padding:"2px 5px"
                            }} 
                                className="form-control" value="1" />
                        </div>
                    </div>
                </div>
                <button id="mintButton" onClick={onMintPressed}>
                    Mint
                </button>
            </p>
            );
        } else {
            setStatus(
                <p>
                    <h2>Connect a Wallet</h2> 
                    {status}
                </p>
                );

        }

        addWalletListener();
    }, []);

    function addWalletListener() {
        if (window.ethereum) {
            window.ethereum.on("accountsChanged", (accounts) => {
                if (accounts.length > 0) {
                    setWallet(accounts[0]);
                    // setStatus("Write a message in the text-field above.");
                    setStatus(
                        <p>
                            {" "}
                            {" "}
                            <div className="row rowMint">
                                <div className="Minter">
                                    <div className="col-9" style={{ float: "left" }}>
                                        <ReactBootstrapSlider
                                            value={1}
                                            change={changeValue}
                                            step={1}
                                            max={30}
                                            min={1}
                                            orientation="horizontal" />
                                    </div>
                                    <div className="col-3" style={{ position: "relative", top: "-4px", float: "left" }}>
                                        <input id="txtMintNumber" style={{
                                            padding: "2px 5px"
                                        }}
                                            className="form-control" value="1" />
                                    </div>
                                </div>
                            </div>
                            <button id="mintButton" onClick={onMintPressed}>
                                Mint
                       </button>
                        </p>
                    );
                } else {
                    setWallet("");
                    setStatus(
                        <p>
                            <h2>Connect a Wallet</h2> 
                            {"Connect to your wallet using the button below."}
                        </p>
                        ); 
                }
            });
        } else {
            setStatus(
                <p>
                    {" "}
                    🦊{" "}
                    <a target="_blank" href={'https://metamask.app.link/dapp/minting.do3.io/ladyclownclub'}>
                        You must install Metamask, a virtual Ethereum wallet, in your
                        browser.
                    </a>
                </p>
            );
        }
    }

    function  changeValue(obj) {
         document.getElementById("txtMintNumber").value = obj.target.value;
    }
    const gobackLCC = async () => {

        document.location = "https://ladyclownclub.com";
    }

    const connectWalletPressed = async () => {

        const walletResponse = await connectWallet();

        if (walletResponse.status == "connected") {
            setStatus(<p>
                {" "}
                {" "}
                <div className="row rowMint">
                    <div className="Minter">
                        <div className="col-9" style={{ float: "left" }}>
                            <ReactBootstrapSlider
                                value={1}
                                change={changeValue}
                                step={1}
                                max={30}
                                min={1}
                                orientation="horizontal" />
                        </div>
                        <div className="col-3" style={{ position: "relative", top: "-4px", float: "left" }}>
                            <input id="txtMintNumber" style={{
                                padding: "2px 5px"
                            }}
                                className="form-control" value="1" />
                        </div>
                    </div>
                </div>
                <button id="mintButton" onClick={onMintPressed}>
                    Mint
            </button>
            </p>
            );
           
            
        } else {
            setStatus(walletResponse.status);

        }

        // setStatus(walletResponse.status);
        setWallet(walletResponse.address);



    };

    const onMintPressed = async () => {
        // console.log("pre mint nft");
        const mintNumber= document.getElementById("txtMintNumber").value;
        setMintNumber(mintNumber);

        //console.log(mintNumber)

        const { success, status } = await mintLCCNFT(url, name, description, mintNumber);
        // setStatus(status);
        //console.log(success);
        //console.log("call swal");

        if (!success) {
            Swal.fire({
                icon: 'error',
                title: 'Oops...',
                text: status
            })
        } else {
            Swal.fire({
                icon: 'success',
                title: 'Success!',
                text: status
            })

        }
        setStatus(<p>
            {" "}
            <section id="slideMint" >
                <div className="row rowMint">
                    <div className="Minter">
                        <div className="col-9" style={{ float: "left" }}>
                            <ReactBootstrapSlider
                                value={1}
                                change={changeValue}
                                step={1}
                                max={30}
                                min={1}
                                orientation="horizontal" />
                        </div>
                        <div className="col-3" style={{ position: "relative", top: "-4px", float: "left" }}>
                            <input id="txtMintNumber" style={{
                                padding: "2px 5px"
                            }}
                                className="form-control" value="1" />
                        </div>
                    </div>
                </div>
                <button id="mintButton" onClick={onMintPressed}>
                    Mint
                </button>
                    
            </section>
        </p>
        );
       
    };


    if (walletAddress.length > 0){

        return (
       
        
            <div data-v-app="" id="app">
                
                <div id="LCC"> 
                    <div style={{ padding: '10px', float: 'left' }}>
                        <a id="backButtonLCC" onClick={gobackLCC}><span>Back</span></a>
                    </div> 
                    <section id="slide" >
                        <div className="mintBox">
                            
                            <div className="row rowMint"> 
                                <div className="Minter"> 
                                    <button id="walletButton" onClick={connectWalletPressed}>
                                        {walletAddress.length > 0 ? (
                                            "Connected: " +
                                            String(walletAddress).substring(0, 6) +
                                            "..." +
                                            String(walletAddress).substring(38)
                                        ) : (
                                            <span>Connect my Wallet</span>
                                        )}
                                    </button>
    
                                    <form>  </form>
    
                                </div>
                            </div>
                            <div className="row rowMint">
                                <p id="status" style={{ width: '100%', color: "red" }}>
                                    {status}
                                </p>
                            </div>
                        </div>
                    </section>
                    
                </div>
            </div>
        );

    }else{

        return (
       
        
            <div data-v-app="" id="app">
                
                <div id="LCC">
                    
                    
                    <div style={{ padding: '10px', float: 'left' }}>
                        <a id="backButtonLCC" onClick={gobackLCC}><span>Back</span></a>
                    </div> 
                    <section id="slide" >
                        <div className="mintBox">
                            <div className="row rowMint">
                                <p id="status" style={{ width: '100%', color: "red" }}>
                                    {status}
                                </p>
                            </div>
                            <div className="row rowMint"> 
                                <div className="Minter"> 
                                    <button id="walletButton1" onClick={connectWalletPressed}>
                                        {walletAddress.length > 0 ? (
                                            "Connected: " +
                                            String(walletAddress).substring(0, 6) +
                                            "..." +
                                            String(walletAddress).substring(38)
                                        ) : (
                                            <span>Connect my Wallet</span>
                                        )}
                                    </button>
    
                                    <form>  </form>
    
                                </div>
                            </div>
                            
                        </div>
                    </section>
                    
                </div>
            </div>
        );

    }
    
};

export default SadClownParty;
