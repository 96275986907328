/* eslint-disable */
 
import { pinFileToIPFS } from "./pinata.js";
require("dotenv").config();
const alchemyKey = process.env.REACT_APP_ALCHEMY_KEY;
const publicKey = process.env.REACT_APP_PUBLIC_KEY; 
const baseURL = process.env.REACT_APP_BASE_URL;
const uploadPath = process.env.REACT_APP_UPLOAD_PATH;
const pkey = process.env.REACT_APP_PINATA_KEY;
const psecret = process.env.REACT_APP_PINATA_SECRET;
const jsonPath = process.env.REACT_APP_UPLOAD_JSON_PATH;
const nftName = process.env.REACT_APP_NFT_NAME;
const nftDescription = process.env.REACT_APP_NFT_DESCRIPTION;
const nftFolderName = process.env.REACT_APP_NFT_FOLDERNAME;
const nftTotalSupply = process.env.REACT_APP_NFT_TOTAL_SUPPLY;
 
const FormData = require('form-data');
const contractABI = require("../contract-abi.json");


const contractABIPixelFoxes = require("../contract-abi-pf.json");
const alchemyKeyPF = process.env.REACT_APP_ALCHEMY_KEY_PF;
const publicKeyPF = process.env.REACT_APP_PUBLIC_KEY_PF; 

const fs = require('fs')
const jquery = require('jquery')
 
//const contractAddress = "0x412dedc34edE1198a27130547A63B6Bfcf65B189"; //ropsten
//const contractAddress = "0x89858b18dc3e10Ce52B8d96D83d17Ec0DBc05a59"; //rinkeby --old 
//const contractAddress = "0xfee6eae3FA88f4e4e0407eE934b807c1707a7016"; //rinkeby --traveltocuans
//const contractAddress = "0x959924974b13EcfbE77dC46b55B9F86E6d816B94"; //rinkby -pf
const contractAddress = "0x23A49E06C3221C9D4f5d4F76F4054aE9705a120c"; //production -tt
const contractAddressPixelFoxes = "0x25171B354F3e192Ea4fB0c1268255D7A64448C91"; //rinkby -pixelfoxe

const { createAlchemyWeb3 } = require("@alch/alchemy-web3");
const web3 = createAlchemyWeb3(alchemyKey);
const web3PF = createAlchemyWeb3(alchemyKeyPF);

export const getOwnedTokens = async() => {

    var tokenInst = await new web3.eth.Contract(contractABI, contractAddress);
   
    tokenInst.methods.balanceOf(window.ethereum.selectedAddress).call().then(function (bal) {
       // console.log(bal);

       for(var i = 0; i < bal.toNumber(); i++) {
           tokenInst.tokenOfOwnerByIndex.call(window.ethereum.selectedAddress, i)
           .then((id) => { 
       
              // console.log("I own token ID:" + id.tostring())
           });       
       }   
 
    })

    /*for(var i = 0; i < balance.toNumber(); i++) {
        myERC721Token.tokenOfOwnerByIndex.call(web3.eth.accounts[0], i)
        .then((id) => { ... });       
    }   
    */

}


function dataURItoBlob(dataURI) {
    // convert base64/URLEncoded data component to raw binary data held in a string
    var byteString;

    if (dataURI.split(',')[0].indexOf('base64') >= 0)
        byteString = atob(dataURI.split(',')[1]);
    else
        byteString = unescape(dataURI.split(',')[1]);

    // separate out the mime component
    var mimeString = dataURI.split(',')[0].split(':')[1].split(';')[0];

    // write the bytes of the string to a typed array
    var ia = new Uint8Array(byteString.length);
    for (var i = 0; i < byteString.length; i++) {
        ia[i] = byteString.charCodeAt(i);
    }

    return new Blob([ia], { type: mimeString });
}


export const connectWallet = async () => {
    if (window.ethereum) {

         //const networkId = await web3.eth.net.getId();
        //const networkType = await web3.eth.net.getNetworkType();

       // console.log(networkId)
       // console.log(networkType)

    try {
      const addressArray = await window.ethereum.request({
        method: "eth_requestAccounts",
      });
      const obj = {
         // status: "👆🏽 Write a message in the text-field above.",
          status: "connected",
        address: addressArray[0],
      };
      return obj;
    } catch (err) {
      return {
        address: "",
        status: "😥 " + err.message,
      };
    }
  } else {
    return {
      address: "",
      status: (
        <span>
          <p>
            {" "}
            🦊{" "}
            <a target="_blank" href={'https://metamask.io/download.html'}>
              You must install Metamask, a virtual Ethereum wallet, in your
              browser.
            </a>
          </p>
        </span>
      ),
    };
  }
};

export const getCurrentWalletConnected = async () => {
    if (window.ethereum) {

        const networkId = await web3.eth.net.getId();
        const networkType = await web3.eth.net.getNetworkType();

        //console.log(networkId)
        //console.log(networkType)
    try {
      const addressArray = await window.ethereum.request({
        method: "eth_accounts",
      });
        if (addressArray.length > 0) {

            //showm mint button here

        return {
          address: addressArray[0],
          status:"connected",
        };
      } else {
        return {
          address: "",
          status: "Connect to Metamask using the top right button.",
        };
      }
    } catch (err) {
      return {
        address: "",
        status: "😥 " + err.message,
      };
    }
  } else {
    return {
      address: "",
      status: (
        <span>
          <p>
            {" "}
            🦊{" "}
            <a target="_blank" href={`https://metamask.io/download.html`}>
              You must install Metamask, a virtual Ethereum wallet, in your
              browser.
            </a>
          </p>
        </span>
      ),
    };
  }
};

async function loadContract() {
  return new web3.eth.Contract(contractABI, contractAddress);
}

export async function loadContract_PF() {
    return new web3PF.eth.Contract(contractABIPixelFoxes, contractAddressPixelFoxes);
}


async function writeIfMissing(id){
    //check if file exists
    try {
        const file = `./sold_tokens/${id}`;
        const final_file = `./final_metadata/${id}`;
        // file missing
        if (!fs.existsSync(file) && fs.existsSync(final_file)) {
            try {
                await this.logTransfer(id);
            } catch (err) {
                console.log(err);
            }
        }
    } catch(err) {
        console.error(err)
    }
}
 



async function writeFile(id,sNFTMetaFileName, jsonString){
    //check if file exists
    try {
      fs.writeFile(sNFTMetaFileName, jsonString, err => {
        if (err) {
            console.log('Error writing file', err)
        } else {
            console.log('Successfully wrote file')

                //now mint 

            }
        })
    } catch(err) {
        console.error(err)
    }
}
async function logTransfer(id) {
    try {
        var oldPath = `./final_metadata/${id}`;
        var newPath = `./sold_tokens/${id}`;

        fs.copyFile(oldPath, newPath, function (err) {
            if (err) throw err
        })
    } catch (err) {
        console.log(err);
    }
}


var saveNote = function(note) {
     

    
};


export const getTotalContractSupply = async () => {

    window.contract = await new web3.eth.Contract(contractABI, contractAddress);
    const nftContract = window.contract; 
    const sTotalSupply = await nftContract.methods.totalSupply().call()
    const nNftTotalSupply = parseInt(nftTotalSupply);
   
    return {
        TotalSupply: nNftTotalSupply 
    };
}


export const premintNFT = async (url, name, description) => {

    const axios = require('axios');
    

    // console.log("1");
    //if (url.trim() == "" || name.trim() == "" || description.trim() == "") {
    //  return {
    //    success: false,
    //    status: "❗Please make sure all fields are completed before minting.",
    //  };
    //}
    //////////////////////////////////////////
    //////////////////////////////////////////
    //1. get contract /////////////////////
    //////////////////////////////////////////
    //////////////////////////////////////////
    window.contract = await new web3.eth.Contract(contractABI, contractAddress);
    const nftContract = window.contract;


    const blnPaused = await nftContract.methods._paused_premit.call().call();
   // console.log(blnPaused);

    if (blnPaused) {
        return {
            success: false,
            status: "Minting has not started.",
        };
    }


    //////////////////////////////////////////
    //////////////////////////////////////////
    //2. Total Supply: this is there minted ID
    //   started at zero
    //////////////////////////////////////////
    //////////////////////////////////////////
    const sTotalSupply = await nftContract.methods.totalSupply().call()
   // console.log(sTotalSupply);
   // console.log(nftTotalSupply);
    const nNftTotalSupply = parseInt(nftTotalSupply);

    if (sTotalSupply >= nNftTotalSupply) {
        return {
            success: false,
            status: "Sold Out!",
        };
    }

    //////////////////////////////////////////
    //////////////////////////////////////////
    //3. Build NFT png
    //////////////////////////////////////////
    //////////////////////////////////////////
    //unreaveled
    /*const file_name = sTotalSupply.toString() + ".png";

    url = baseURL + "/" + nftFolderName + "/" + file_name

    */
    // console.log(url);

    //const image_path = "./tt_premint/" + sTotalSupply.toString() + ".png"

    //make metadata for ipfs /// not needed
    //const metadata = new Object();
    // metadata.name = name;
    // metadata.image = url;
    // metadata.description = description;

    //////////////////////////////////////////
    //////////////////////////////////////////
    //4. Call script to generate artwork and upload file to IPFS hash
    //////////////////////////////////////////
    ////////////////////////////////////////// 
    var ipfsHash = "";

    console.log("4");

    //unrevealed
    //axios.post('https://tokentoucan.com/api/uploadFileToIPFS', {
    //    file_name: file_name
    //})
    //    .then(function (response) {
    // console.log(response);
    //console.log(response);
    //console.log(response.data);

    (async () => {
        //unreavels
        /*
        if (response.data == "ERROR" || response.data  == "") {

            return {
                success: false,
                status: "An error occurred. Please try again. ",
            };

        }
         
        ipfsHash = response.data;
         */

        //////////////////////////////////////////
        //////////////////////////////////////////
        //5. build IPFS url
        //////////////////////////////////////////
        //////////////////////////////////////////
        //unrevealed
        /*
        const ipfsURI = "https://ipfs.io/ipfs/"
        const ipfsURL = ipfsURI + ipfsHash;
        */
        //console.log("Pinata IPFS:" +ipfsURL );

        //////////////////////////////////////////
        //////////////////////////////////////////
        //6. build json and save to server to send to eth contract and for our baby opensea
        //////////////////////////////////////////
        //////////////////////////////////////////
        //unrevealed
        /* const sDescriptionTT = "Travel Toucans (“TT” for short) are here to inspire us all to get back out there, exploring " +
            "the world (when it’s safe and possible again).The TT community will be one where members " +
            "can discuss their travel dreams, share favorite parts from previous trips, ask advice from others and even eventually " +
            "create their own NFTs of their travel pictures! ";
        const sDescription = nftDescription;
        const sIPFS = ipfsURL;
        const sGoogleImg = "";
        // total supply of get tokens that will be the currecn number
        const nftmetadata = {
            description: sDescription,
            image: ipfsURL,
            name: nftName + " #" + sTotalSupply ,
            ipfs_image: sIPFS
           // google_image: sGoogleImg
        };
       */

        //console.log(jsonString);

        //////////////////////////////////////////
        //////////////////////////////////////////
        //7. save json to server on confirmation mint
        //////////////////////////////////////////
        //////////////////////////////////////////
        /*const jsonString = JSON.stringify(nftmetadata);
        const sNFTMetaFileNamePath = jsonPath + nftFolderName + '\\' + sTotalSupply + '.json';

        axios.post('https://tokentoucan.com/api/notes', {
            data: jsonString,
            path: sNFTMetaFileNamePath
        })
        .then(function (response) {
            console.log(response);
             
            (async () => {
    */
        //////////////////////////////////////////
        //////////////////////////////////////////
        //8. GREEN LIGHT HOUSTON CLEAR TO MINT MINT MINT 
        //////////////////////////////////////////
        //////////////////////////////////////////
        const sPrice = await nftContract.methods.getPrice().call()
        //console.log(sPrice.toString())
        //set price
        const amountToSend = 22000000000000000// 22000000000000000;.022 eth
        const nonce = await web3.eth.getTransactionCount(window.ethereum.selectedAddress, 'latest'); //get latest nonce
        const ethAddress = window.ethereum.selectedAddress;
        const sGasPrice = web3.utils.toHex(await web3.eth.getGasPrice());
        //the transaction
        const tx = {
            from: ethAddress,
            to: contractAddress,
            gasPrice: sGasPrice,
            value: web3.utils.toHex(web3.utils.toWei('0.022', 'ether')),
            data: nftContract.methods.freebirdPremint(1).encodeABI()
        };
        //print transaction hash
        //console.log(JSON.stringify(tx))

        try {
            const txHash = await window.ethereum.request({
                method: "eth_sendTransaction",
                params: [tx],
            });

            //console.log(txHash);
            return {
                success: true,
                status:
                "✅ Check out your transaction on Etherscan: https://rinkeby.etherscan.io/tx/" +
                txHash,
            };
        } catch (error) {
            //console.log(error.message);
            return {
                success: false,
                status: "😥 Something went wrong: " + error.message,
            };
        }

        /*  })();

          })

          .catch(function (error) {
              console.log("Error" + error);
          });
           */

    })();

    //unrevealed
    /*})

    .catch(function (error) {
        console.log("Error" + error);
    });
    */

    return {
        success: false,
        status: "😥 Something went wrong: ",
    };
    // const jsonString2  = JSON.stringify(customer);

    //call webservice to write file.
    //writeFile(sNFTMetaFileName, jsonString);

    /* fs.writeFile(sNFTMetaFileName, jsonString, err => {
         if (err) {
               console.log('Error writing file', err)
     } else {
         console.log('Successfully wrote file')
   
         //now mint 
   
     }
     })*/


    //const signature = await web3.eth.accounts.signTransaction(tx, PRIVATE_KEY);
    //console.log(signature);
    //const signPromise = web3.eth.accounts.signTransaction(tx, PRIVATE_KEY);
    //signPromise.then((signedTx) => {

    //    web3.eth.sendSignedTransaction(signedTx.rawTransaction, function(err, hash) {
    //        if (!err) {
    //            console.log("The hash of your transaction is: ", hash, "\nCheck Alchemy's Mempool to view the status of your transaction!"); 
    //        } else {
    //            console.log("Something went wrong when submitting your transaction:", err)
    //        }
    //    });
    //}).catch((err) => {
    //    console.log(" Promise failed:", err);
    //});

    //return;
    /////
    //////
    /////
    //////
    /////
    //////


    /* const transactionParameters = {
        to: contractAddress, // Required except during contract publications.
        from: window.ethereum.selectedAddress, // must match user's active address.
        data: window.contract.methods
         // .mintNFT(window.ethereum.selectedAddress, ipfsURL)
          .freebird(1)
          .encodeABI(),
      };
      */

    //const transactionParameters = {
    //    to: contractAddress, // Required except during contract publications.
    //    from: window.ethereum.selectedAddress, // must match user's active address.
    //    data: window.contract.methods 
    //      .freebird(1).call(),
    //};


};

export const TESTmintNFT = async (url, name, description) => {

    const axios = require('axios');

    // console.log("1");
    //if (url.trim() == "" || name.trim() == "" || description.trim() == "") {
    //  return {
    //    success: false,
    //    status: "❗Please make sure all fields are completed before minting.",
    //  };
    //}
    //////////////////////////////////////////
    //////////////////////////////////////////
    //1. get contract /////////////////////
    //////////////////////////////////////////
    //////////////////////////////////////////
    window.contract = await new web3.eth.Contract(contractABI, contractAddress);
    const nftContract = window.contract;

    const blnPaused = await nftContract.methods._paused_premint.call().call();
    //console.log(blnPaused);

    if (blnPaused) {
        return {
            success: false,
            status: "Minting has not started.",
        };
    }


    //////////////////////////////////////////
    //////////////////////////////////////////
    //2. Total Supply: this is there minted ID
    //   started at zero
    //////////////////////////////////////////
    //////////////////////////////////////////
    const sTotalSupply = await nftContract.methods.totalSupply().call()
    //console.log(sTotalSupply);
    //console.log(nftTotalSupply);
    const nNftTotalSupply = parseInt(nftTotalSupply);

    //if (sTotalSupply >= nNftTotalSupply) {
    if (sTotalSupply >= 70) {
        return {
            success: false,
            status: "Premint Sold Out!",
        };
    }

    //////////////////////////////////////////
    //////////////////////////////////////////
    //3. Build NFT png
    //////////////////////////////////////////
    //////////////////////////////////////////
    //unreaveled
    /*const file_name = sTotalSupply.toString() + ".png";

    url = baseURL + "/" + nftFolderName + "/" + file_name

    */
    // console.log(url);

    //const image_path = "./tt_premint/" + sTotalSupply.toString() + ".png"

    //make metadata for ipfs /// not needed
    //const metadata = new Object();
    // metadata.name = name;
    // metadata.image = url;
    // metadata.description = description;

    //////////////////////////////////////////
    //////////////////////////////////////////
    //4. Call script to generate artwork and upload file to IPFS hash
    //////////////////////////////////////////
    ////////////////////////////////////////// 
    var ipfsHash = "";

    //console.log("4");

    //unrevealed
    //axios.post('https://tokentoucan.com/api/uploadFileToIPFS', {
    //    file_name: file_name
    //})
    //.then(function (response) {
    // console.log(response);
    //console.log(response);
    //console.log(response.data);

    // (async () => {
    //unreavels
    /*
    if (response.data == "ERROR" || response.data  == "") {

        return {
            success: false,
            status: "An error occurred. Please try again. ",
        };

    }
     
    ipfsHash = response.data;
     */

    //////////////////////////////////////////
    //////////////////////////////////////////
    //5. build IPFS url
    //////////////////////////////////////////
    //////////////////////////////////////////
    //unrevealed
    /*
    const ipfsURI = "https://ipfs.io/ipfs/"
    const ipfsURL = ipfsURI + ipfsHash;
    */
    //console.log("Pinata IPFS:" +ipfsURL );

    //////////////////////////////////////////
    //////////////////////////////////////////
    //6. build json and save to server to send to eth contract and for our baby opensea
    //////////////////////////////////////////
    //////////////////////////////////////////
    //unrevealed
    /* const sDescriptionTT = "Travel Toucans (“TT” for short) are here to inspire us all to get back out there, exploring " +
        "the world (when it’s safe and possible again).The TT community will be one where members " +
        "can discuss their travel dreams, share favorite parts from previous trips, ask advice from others and even eventually " +
        "create their own NFTs of their travel pictures! ";
    const sDescription = nftDescription;
    const sIPFS = ipfsURL;
    const sGoogleImg = "";
    // total supply of get tokens that will be the currecn number
    const nftmetadata = {
        description: sDescription,
        image: ipfsURL,
        name: nftName + " #" + sTotalSupply ,
        ipfs_image: sIPFS
       // google_image: sGoogleImg
    };
   */

    //console.log(jsonString);

    //////////////////////////////////////////
    //////////////////////////////////////////
    //7. save json to server on confirmation mint
    //////////////////////////////////////////
    //////////////////////////////////////////
    /*const jsonString = JSON.stringify(nftmetadata);
    const sNFTMetaFileNamePath = jsonPath + nftFolderName + '\\' + sTotalSupply + '.json';

    axios.post('https://tokentoucan.com/api/notes', {
        data: jsonString,
        path: sNFTMetaFileNamePath
    })
    .then(function (response) {
        console.log(response);
            
        (async () => {
    */
    //////////////////////////////////////////
    //////////////////////////////////////////
    //8. GREEN LIGHT HOUSTON CLEAR TO MINT MINT MINT 
    //////////////////////////////////////////
    //////////////////////////////////////////
    const sPrice = await nftContract.methods.getPrice().call()
    //console.log(sPrice.toString())
    //set price
    const amountToSend = 22000000000000000// 22000000000000000;.022 eth
    const nonce = await web3.eth.getTransactionCount(window.ethereum.selectedAddress, 'latest'); //get latest nonce
    const ethAddress = window.ethereum.selectedAddress;
    const sGasPrice = web3.utils.toHex(await web3.eth.getGasPrice());
    //the transaction
    const tx = {
        from: ethAddress,
        to: contractAddress,
        gasPrice: sGasPrice,
        value: web3.utils.toHex(web3.utils.toWei('0.022', 'ether')),
        data: nftContract.methods.freebirdPremint(1).encodeABI()
    };

    //print transaction hash
    //console.log(JSON.stringify(tx))

    try {

        const txHash = await window.ethereum.request({
            method: "eth_sendTransaction",
            params: [tx],
        });

        //console.log("tx hash");

        return {
            success: true,
            status:
            "✅ Successful transaction on Etherscan: https://rinkeby.etherscan.io/tx/" +
            txHash,
        };

    } catch (error) {

        //console.log(error.message);
        //console.log(error.message);
        return {
            success: false,
            status: "test Something went wrong: " + error.message,
        };

    }

    /*  })();

      })

      .catch(function (error) {
          console.log("Error" + error);
      });
       */

    // })();

    //unrevealed
    /*})

    .catch(function (error) {
        console.log("Error" + error);
    });
    */


    // const jsonString2  = JSON.stringify(customer);

    //call webservice to write file.
    //writeFile(sNFTMetaFileName, jsonString);

    /* fs.writeFile(sNFTMetaFileName, jsonString, err => {
         if (err) {
               console.log('Error writing file', err)
     } else {
         console.log('Successfully wrote file')
   
         //now mint 
   
     }
     })*/


    //const signature = await web3.eth.accounts.signTransaction(tx, PRIVATE_KEY);
    //console.log(signature);
    //const signPromise = web3.eth.accounts.signTransaction(tx, PRIVATE_KEY);
    //signPromise.then((signedTx) => {

    //    web3.eth.sendSignedTransaction(signedTx.rawTransaction, function(err, hash) {
    //        if (!err) {
    //            console.log("The hash of your transaction is: ", hash, "\nCheck Alchemy's Mempool to view the status of your transaction!"); 
    //        } else {
    //            console.log("Something went wrong when submitting your transaction:", err)
    //        }
    //    });
    //}).catch((err) => {
    //    console.log(" Promise failed:", err);
    //});

    //return;
    /////
    //////
    /////
    //////
    /////
    //////


    /* const transactionParameters = {
        to: contractAddress, // Required except during contract publications.
        from: window.ethereum.selectedAddress, // must match user's active address.
        data: window.contract.methods
         // .mintNFT(window.ethereum.selectedAddress, ipfsURL)
          .freebird(1)
          .encodeABI(),
      };
      */

    //const transactionParameters = {
    //    to: contractAddress, // Required except during contract publications.
    //    from: window.ethereum.selectedAddress, // must match user's active address.
    //    data: window.contract.methods 
    //      .freebird(1).call(),
    //};


};

//clean code function
export const mintNFT = async (url, name, description) => {

    const axios = require('axios');
   
    window.contract = await new web3.eth.Contract(contractABI, contractAddress);
    const nftContract = window.contract;
     
    const blnPaused = await nftContract.methods._paused_premint.call().call();
    
    if (blnPaused) {
        return {
            success: false,
            status: "Minting not active.",
        };
    }
    
    const sTotalSupply = await nftContract.methods.totalSupply().call()
    const nNftTotalSupply = parseInt(nftTotalSupply);

    //if (sTotalSupply >= nNftTotalSupply) {
    if (sTotalSupply >= 70) {
        return {
            success: false,
            status: "Premint Sold Out!",
        };
    }
    
    const sPrice = await nftContract.methods.getPrice().call() 
    const amountToSend = 22000000000000000// 22000000000000000;.022 eth
    const nonce = await web3.eth.getTransactionCount(window.ethereum.selectedAddress, 'latest'); //get latest nonce
    const ethAddress = window.ethereum.selectedAddress;
    const sGasPrice = web3.utils.toHex(await web3.eth.getGasPrice());
    //the transaction
    const tx = {
        from:  ethAddress,
        to: contractAddress, 
        gasPrice: sGasPrice, 
        value: web3.utils.toHex(web3.utils.toWei('0.022', 'ether')),
        data: nftContract.methods.freebirdPremint(1).encodeABI()
    };
    
    try {

        const txHash = await window.ethereum.request({
            method: "eth_sendTransaction",
            params: [tx],
        });
         
        return {
            success: true,
            status:
                "✅ Successful transaction. Check on Etherscan: https://etherscan.io/tx/" +
                txHash,
        };

    } catch (error) {
        
        return {
            success: false,
            status: "Something went wrong: " + error.message,
        };

    }
        
};
 
export const mintNFT_RealMint = async (url, name, description) => {

    const axios = require('axios');

    window.contract = await new web3.eth.Contract(contractABI, contractAddress);
    const nftContract = window.contract;

    const blnPaused = await nftContract.methods._paused.call().call();

    if (blnPaused) {
        return {
            success: false,
            status: "Minting not active.",
        };
    }

    const sTotalSupply = await nftContract.methods.totalSupply().call()
    const nNftTotalSupply = parseInt(nftTotalSupply);

    if (sTotalSupply >= nNftTotalSupply) {
         return {
            success: false,
            status: "Premint Sold Out!",
        };
    }

    const sPrice = await nftContract.methods.getPrice().call()
    const amountToSend = 22000000000000000// 22000000000000000;.022 eth
    const nonce = await web3.eth.getTransactionCount(window.ethereum.selectedAddress, 'latest'); //get latest nonce
    const ethAddress = window.ethereum.selectedAddress;
    const sGasPrice = web3.utils.toHex(await web3.eth.getGasPrice());
    //the transaction
    const tx = {
        from: ethAddress,
        to: contractAddress,
        gasPrice: sGasPrice,
        value: web3.utils.toHex(web3.utils.toWei('0.022', 'ether')),
        data: nftContract.methods.freebird(1).encodeABI()
    };

    try {

        const txHash = await window.ethereum.request({
            method: "eth_sendTransaction",
            params: [tx],
        });

        return {
            success: true,
            status:
            "✅ Successful transaction. Check on Etherscan: https://etherscan.io/tx/" +
            txHash,
        };

    } catch (error) {

        return {
            success: false,
            status: "Something went wrong: " + error.message,
        };

    }

};

 
//clean code function
export const mintPixelFoxesNFT = async (url, name, description, mintNumber) => {
     const nMintNumber = parseInt(mintNumber);
    
    if (nMintNumber > 30) { 
        return {
            success: false,
            status: "Mint cannot exceed 30",
        }; 
    }
    
    //window.contract = await new web3PF.eth.Contract(contractABIPixelFoxes, contractAddressPixelFoxes);
    window.contract = await new web3PF.eth.Contract(contractABIPixelFoxes, contractAddressPixelFoxes);

    const nftContractPF = window.contract;
     
    /*nftContractPF.events.FoxMint(() => {
    }).on('data', function (event) {
        console.log(event);
    }).on('error', function (error) {
        console.log(error);
    });*/
    console.log("test" )
    const blnPausedPF = await nftContractPF.methods._paused.call().call();

    console.log("test" + blnPausedPF)

    if (blnPausedPF) {
        console.log("return");
        return {
            success: false,
            status: "Minting not active.",
        };
    }

    const sTotalSupply = await nftContractPF.methods.totalSupply().call()
    const nNftTotalSupply = parseInt(nftTotalSupply);

    //if (sTotalSupply >= nNftTotalSupply) {
    if (sTotalSupply >= 10000) {
        return {
            success: false,
            status: "Premint Sold Out!",
        };
    }

    const sPrice = await nftContractPF.methods.getPrice().call()
    const amountToSend = 10000000000000000// 10000000000000000;.01 eth
    const nonce = await web3PF.eth.getTransactionCount(window.ethereum.selectedAddress, 'latest'); //get latest nonce
    const ethAddress = window.ethereum.selectedAddress;
    const sGasPrice = web3PF.utils.toHex(await web3PF.eth.getGasPrice());
    //the transaction
    const tx = {
        from: ethAddress,
        to: contractAddress,
        gasPrice: sGasPrice,
        value: web3PF.utils.toHex(web3PF.utils.toWei('0.01', 'ether')),
        data: nftContractPF.methods.FoxMint(nMintNumber).encodeABI()
    };

    try {

        const txHash = await window.ethereum.request({
            method: "eth_sendTransaction",
            params: [tx],
        });

        return {
            success: true,
            status:
            "✅ Successful transaction. Check on Etherscan: https://etherscan.io/tx/" +
            txHash,
        };

    } catch (error) {

        return {
            success: false,
            status: "Something went wrong: " + error.message,
        };

    }

};
