/* eslint-disable */ 
import { pinFileToIPFS } from "./pinata.js";
require("dotenv").config();

const fs = require('fs')
const jquery = require('jquery')
var BigNumber = require('big-number');
 
const alchemyKey = process.env.REACT_APP_ALCHEMY_KEY_SOS;
const publicKey = process.env.REACT_APP_PUBLIC_KEY_SOS; 
const baseURL = process.env.REACT_APP_BASE_URL_SOS;
const uploadPath = process.env.REACT_APP_UPLOAD_PATH_SOS;
const pkey = process.env.REACT_APP_PINATA_KEY_SOS;
const psecret = process.env.REACT_APP_PINATA_SECRET_SOS;
const jsonPath = process.env.REACT_APP_UPLOAD_JSON_PATH_SOS;
const nftName = process.env.REACT_APP_NFT_NAME_SOS;
const nftDescription = process.env.REACT_APP_NFT_DESCRIPTION_SOS;
const nftFolderName = process.env.REACT_APP_NFT_FOLDERNAME_SOS;
const nftTotalSupply = process.env.REACT_APP_NFT_TOTAL_SUPPLY_SOS;
 
const FormData = require('form-data');
const contractABI = require("../contract-abi-sos.json");
const contractABIErc20 = require("../contract-abi-sos-erc20.json");

const alchemyKeyPF = process.env.REACT_APP_ALCHEMY_KEY_SOS;
const publicKeyPF = process.env.REACT_APP_PUBLIC_KEY_SOS;
const contractAddress = process.env.REACT_APP_PUBLIC_KEY_SOS; 
const contractAddressErc20 = process.env.REACT_APP_PUBLIC_KEY_SOS_ERC20; 
const contractSOSMintPrice = process.env.REACT_APP_MINT_PRICE_SOS; //price
 

 


const { createAlchemyWeb3 } = require("@alch/alchemy-web3");
const web3 = createAlchemyWeb3(alchemyKey);
const web3Erc20 = createAlchemyWeb3(alchemyKey);
const Web3 = require("web3");

export const connectWallet = async () => {
    if (window.ethereum) {
         
        try {
          const addressArray = await window.ethereum.request({
            method: "eth_requestAccounts",
          });
          

          const obj = {
             // status: "👆🏽 Write a message in the text-field above.",
              status: "connected",
            address: addressArray[0],
          };
          return obj;
        } catch (err) {
          return {
            address: "",
            status: "😥 " + err.message,
          };
        }
      } else {
        return {
          address: "",
          status: (
            <span>
              <p>
                {" "}
                🦊{" "}
                <a target="_blank" href={'https://metamask.io/download.html'}>
                  You must install Metamask, a virtual Ethereum wallet, in your
                  browser.
                </a>
              </p>
            </span>
          ),
        };
      }
};

export const getCurrentWalletConnected = async () => {
  if (window.ethereum) {

        //const networkId = await web3.eth.net.getId();
        //const networkType = await web3.eth.net.getNetworkType();

        //console.log(networkId)
        //console.log(networkType)
    try {
      const addressArray = await window.ethereum.request({
        method: "eth_accounts",
      });
        if (addressArray.length > 0) {

            //showm mint button here
 
        return {
          address: addressArray[0],
          status:"connected",
        };
      } else {
        return {
          address: "",
          status: "Connect to Metamask using the top right button.",
        };
      }
    } catch (err) {
      return {
        address: "",
        status: "😥 " + err.message,
      };
    }
  } else {
    return {
      address: "",
      status: (
        <span>
          <p>
            {" "}
            🦊{" "}
            <a target="_blank" href={`https://metamask.io/download.html`}>
              You must install Metamask, a virtual Ethereum wallet, in your
              browser.
            </a>
          </p>
        </span>
      ),
    };
  }
};
 

export const getOwnedTokensSOS = async() => {

  var tokenInst = await new web3Erc20.eth.Contract(contractABIErc20, contractAddressErc20);

  var balance = await tokenInst.methods.balanceOf(window.ethereum.selectedAddress).call();
 
  return balance;
 

}
export const approveSOS = async(nMintNumber) => {
  
    const sMintPrice = (nMintNumber * contractSOSMintPrice) //(nMintNumber * 0.042)
    const finalMintPrice = BigInt(web3.utils.toWei(String(sMintPrice)))
  
    var bal = await getOwnedTokensSOS();
    const totalBalance = BigInt(bal)
    
    if (totalBalance <= finalMintPrice) {
        return {
          success_approve: false,
          status_approve: "You must own at least " + sMintPrice + " SOS.",
      };
    }  

    const ethAddress = window.ethereum.selectedAddress;
    var tokenInst = await new web3Erc20.eth.Contract(contractABIErc20, contractAddressErc20);
 
    var alloweance =  await tokenInst.methods.allowance(ethAddress,contractAddress).call();
    var nAllowance = BigInt(alloweance);
 
   if (nAllowance < finalMintPrice){
      var balance = await tokenInst.methods.balanceOf(window.ethereum.selectedAddress).call();
 
      //hide ui  
      const txHash2 = await tokenInst.methods.approve(contractAddress,web3.utils.toWei(String(balance))).send({from: ethAddress}) 
       
      return {
        success_approve: true,
        status_approve:
          "✅  Approved successfully. You can now mint. " 
      } 
      
   
    }else{
      return {
        success_approve: true,
        status_approve:
        "✅  Approved successfully. You can now mint. ",
    } 
    

    } 
  

}
export const submitMint = async(nftContractPF,nMintNumber,sMintPrice) => {

    const ethAddress = window.ethereum.selectedAddress;
    const sGasPrice = web3.utils.toHex(await web3.eth.getGasPrice());

    //the transaction
    const tx = {
      from: ethAddress,
      to: contractAddress,
      gasPrice: sGasPrice, 
      value:web3.utils.toHex(web3.utils.toWei(String(sMintPrice), 'ether')),
      data: nftContractPF.methods.SOSTokenMint(web3.utils.toBN(nMintNumber)).encodeABI()
    };


    const txHash = await window.ethereum.request({
      method: "eth_sendTransaction",
      params: [tx],
    });

    return txHash;
 

}


export const getNetworkStatus = async() => {
  const ethereum = await web3Instance();
  if (!ethereum) return -1;
  const chainId = await ethereum.eth.getChainId();
 console.log(chainId);
  return chainId;
};

const web3Instance = () => {
  if (window.ethereum) {
      return new Web3(window.ethereum);
  } else if (window.web3) {
      return new Web3(window.currentProvider);
  }
};


//clean code function
export const mintSOSNFT = async (url, name, description, mintNumber) => {
   
  const network = await getNetworkStatus()
      
  if (network != 1){
    return {
      success: false,
      status: "Wrong Network. Please connect to Ethereum mainnet to continue.",
    };  
  }

  const nMintNumber = mintNumber;
  const sMintPrice = (nMintNumber * contractSOSMintPrice) //(nMintNumber * 0.042)
  const finalMintPrice = BigInt(web3.utils.toWei(String(sMintPrice)))
  //console.log(finalMintPrice);
  
  var bal = await getOwnedTokensSOS();
  //const totalBalance = Number(web3.utils.toWei(String(bal)))
  const totalBalance = BigInt(bal)
  
  if (totalBalance <= finalMintPrice) {
      return {
        success: false,
        status: "You must own at least " + sMintPrice + " SOS.",
    };
  }  
 
  if (nMintNumber > 30) { 
      return {
          success: false,
          status: "Mint cannot exceed 30",
      }; 
  }
    
    window.contract = await new web3.eth.Contract(contractABI, contractAddress); 
     
    const nftContractPF = window.contract; 
    const blnPausedPF = await nftContractPF.methods._pausedErc20Mint.call().call();
    
    if (blnPausedPF) {
        
        return {
            success: false,
            status: "Minting not active.",
        };
    }
    
    const sTotalSupply = await nftContractPF.methods.totalSupply().call()
    
   // const nNftTotalSupply = parseInt(nftTotalSupply); 
    //if (sTotalSupply >= nNftTotalSupply) {

    if (sTotalSupply >= 10000) {
        return {
            success: false,
            status: "Mint Sold Out!",
        };
    }
  
     
    const ethAddress = window.ethereum.selectedAddress;
    const sGasPrice = web3.utils.toHex(await web3.eth.getGasPrice());

    try {

      var tokenInst = await new web3Erc20.eth.Contract(contractABIErc20, contractAddressErc20);
   
      var alloweance =  await tokenInst.methods.allowance(ethAddress,contractAddress).call();
      var nAllowance = BigInt(alloweance);
 
      if (nAllowance < finalMintPrice){
        
        //hide ui  
        const txHash2 = await tokenInst.methods.approve(contractAddress,web3.utils.toWei(String(sMintPrice))).send({from: ethAddress}) 
        .on('confirmation', function(hash){
 
            return {
                success: true,
                status:
                "✅  Approved successfully. You can now mint. " +
                txHash,
            } 
        })
        .on('error', function(error){

          
            return {
              success: false,
              status: "Something went wrong: " + error.message,
          };
        });
     
      }else{

          var txHash = await submitMint(nftContractPF,nMintNumber,sMintPrice);
    
          return {
              success: true,
              status:
              "✅ Successful transaction. Check on Etherscan: https://etherscan.io/tx/" +
              txHash,
          };
     
      }
      
    } catch (error) {

        return {
            success: false,
            status: "Something went wrong: " + error.message,
        };

    }

};
