/* eslint-disable */
import { useEffect, useState } from "react";
import {
    connectWallet,
    getCurrentWalletConnected,
    mintPixelFoxesNFT,  
    getOwnedTokensOFCollection,
    getOwnedTokensSSCollection,
} from "../util/interact_PFSpookySeason.js";
import Swal from 'sweetalert2'

import ReactBootstrapSlider from 'react-bootstrap-slider';
import "../css/bootstrap.css";
import "../css/bootstrap-slider.css"
 
const SliderDiv = (props) => {
    

    return (
        <div>
            
            <input id="val" type="text"></input>
        </div>
        
        );
};

const PixelfoxesSpookySeason = (props) => {
    
    const [walletAddress, setWallet] = useState("");
    const [status, setStatus] = useState("");

    const [name, setName] = useState("");
    const [description, setDescription] = useState("");
    const [url, setURL] = useState("");
    const [mintNumber, setMintNumber] = useState("");

    useEffect(async () => {
        const { address, status } = await getCurrentWalletConnected();

        setWallet(address);

        if (status == "connected") {
             
            var balSS = await getOwnedTokensSSCollection();

            if (parseInt(balSS) > 1) {
                    
                    Swal.fire({
                        icon: 'error',
                        title: 'Oops...',
                        text: 'You have minted the maximum amount of Spooky Foxes for this wallet. Please move to another wallet to mint more.'
                    })
                    return;
            }
            
                
            setStatus(<p>
                {" "}
                <p id="status-info">Your free spooky reward awaits below.<br />
                    You can only mint a maximum of 2 Spooky Foxes per wallet and only 1 per transaction.
                </p> 
                <div className="row rowMint">
                    <div className="Minter">
                        <button id="mintButton" onClick={onMintPressed}>
                            Mint
                         </button>
                    </div>
                </div> 
            </p>
            );

             
            
        } else {
            setStatus(status);

        }

        addWalletListener();
    }, []);

    function addWalletListener() {
        if (window.ethereum) {
            window.ethereum.on("accountsChanged", (accounts)  => {
                 
                addWalletListener2(accounts);
               
            });
        } else {
            setStatus(
                <p>
                    {" "}
                    🦊{" "}
                    <a target="_blank" href={`https://metamask.io/download.html`}>
                        You must install Metamask, a virtual Ethereum wallet, in your
                        browser.
          </a>
                </p>
            );
        }
    }


    const addWalletListener2 = async (accounts) => {

        if (accounts.length > 0) {
            setWallet(accounts[0]);

            var balSS =  await getOwnedTokensSSCollection();

            if (parseInt(balSS) > 1) {
                    
                Swal.fire({
                    icon: 'error',
                    title: 'Oops...',
                    text: 'You have minted the maximum amount of Spooky Foxes for this wallet. Please move to another wallet to mint more.'
                })
                return;
            }
            
                
            setStatus(<p>
                {" "}
                <p id="status-info">Your free spooky reward awaits below.<br />
                    You can only mint a maximum of 2 Spooky Foxes per wallet and only 1 per transaction.
                </p> 
                <div className="row rowMint">
                    <div className="Minter">
                        <button id="mintButton" onClick={onMintPressed}>
                            Mint
                        </button>
                    </div>
                </div> 
            </p>
            );

           


        } else {
            setWallet("");
            setStatus("Connect to Metamask using the top right button.");
        }

    }


    function  changeValue(obj) {
         document.getElementById("txtMintNumber").value = obj.target.value;
    }
    const gobackPixelFoxes = async () => {

        document.location = "https://pixelfoxesnft.com";
    }

    const connectWalletPressed = async () => {

        const walletResponse = await connectWallet();

        if (walletResponse.status == "connected") {

            
            var balSS = await getOwnedTokensSSCollection();

            if (parseInt(balSS) > 1) {
                    
                Swal.fire({
                    icon: 'error',
                    title: 'Oops...',
                    text: 'You have minted the maximum amount of Spooky Foxes for this wallet. Please move to another wallet to mint more.'
                })
                return;
            }
            
                
            setStatus(<p>
                {" "}
                <p id="status-info">Your free spooky reward awaits below.<br />
                    You can only mint a maximum of 2 Spooky Foxes per wallet and only 1 per transaction.
                </p> 
                <div className="row rowMint">
                    <div className="Minter">
                        <button id="mintButton" onClick={onMintPressed}>
                            Mint
                        </button>
                    </div>
                </div> 
            </p>
            );

 
        } else {
            setStatus(walletResponse.status);

        }

        // setStatus(walletResponse.status);
        setWallet(walletResponse.address);



    };

    const onMintPressed = async () => {
        // console.log("pre mint nft");

        var balSS = await getOwnedTokensSSCollection();

        if (parseInt(balSS) > 1) {

            Swal.fire({
                icon: 'error',
                title: 'Oops...',
                text: 'You have minted the maximum amount of Spooky Foxes for this wallet. Please move to another wallet to mint more.'
            })
            return;
        }



        const { success, status } = await mintPixelFoxesNFT(url, name, description);
        // setStatus(status);
        //console.log(success);
        //console.log("call swal");

        if (!success) {
            Swal.fire({
                icon: 'error',
                title: 'Oops...',
                text: status
            })
        } else {
            Swal.fire({
                icon: 'success',
                title: 'Success!',
                text: status
            })

        }


       
        var balSS = await getOwnedTokensSSCollection();

        if (parseInt(balSS) > 1) {
                    
            Swal.fire({
                icon: 'error',
                title: 'Oops...',
                text: 'You have minted the maximum amount of Spooky Foxes for this wallet. Please move to another wallet to mint more.'
            })
            return;
        }
        
            
        setStatus(<p>
            {" "}
            <p id="status-info">Your free spooky reward awaits below.<br />
                You can only mint a maximum of 2 Spooky Foxes per wallet and only 1 per transaction.
            </p> 
            <div className="row rowMint">
                <div className="Minter">
                    <button id="mintButton" onClick={onMintPressed}>
                        Mint
                    </button>
                </div>
            </div> 
        </p>
        );


        



    };
    //color scheme: #aaf8fe
    return (
       
        
        <div data-v-app="" id="app">
            
            <div id="PixelfoxesSpookySeason">
                <div id="divBackButtonPixelFoxes" style={{ padding: '10px', float: 'left' }}>
                    <button id="backButtonPixelFoxes" onClick={gobackPixelFoxes}><span>Back</span></button>
                </div> 
                <img src="../images/spooky-halloween.png" style={{ position:'relative',left:'-80px', width: '800px' }} />
                 
                <section id="slide" >
                    <div className="row rowMint">

                        <div className="Minter">
                            
                            <button id="walletButton" onClick={connectWalletPressed}>
                                {walletAddress.length > 0 ? (
                                    "Connected: " +
                                    String(walletAddress).substring(0, 6) +
                                    "..." +
                                    String(walletAddress).substring(38)
                                ) : (
                                    <span>Connect Wallet</span>
                                )}
                            </button>

                            <form>  </form>

                        </div>
                    </div>
                    <div className="row rowMint">
                        <p id="status" style={{ width: '100%', color: "red" }}>
                            {status}
                        </p>
                    </div>
                </section>
            </div>
        </div>
    );
};

export default PixelfoxesSpookySeason;
