/* eslint-disable */
import React from 'react';
import './App.css';
import Minter from './projects/Minter'
import Traveltoucans from './projects/Traveltoucans'
import Pixelfoxes from './projects/Pixelfoxes'
import PixelfoxesSpookySeason from './projects/PixelfoxesSpookySeason'
import PunkRocks from './projects/PunkRocks'
import PunkAsses from './projects/PunkAsses'
import SadClownParty from './projects/SadClownParty';
import SOS from './projects/SOS';

 
var url = require('url');

var getUrl = (window.location).toString();
//console.log(getUrl);
var getAddress = url.parse(getUrl, true);
var getPathname = getAddress.pathname;
//console.log(getPathname);
 
  
class App extends React.Component {
 
    render() {
        const handlePunkasses = () => {
            const favicon = document.getElementById("favicon"); 
            favicon.href = "./favicon_pa.ico";
          };
        const handleLccFavicon = () => {
            const favicon = document.getElementById("favicon");
            favicon.href = "./favicon_lcc.ico";
        };
        const updatePunkasses = () => { 
            document.body.style.background = "#16173e";
        };
        const updateLccBg = () => { 
            document.body.style.backgroundImage = 'url(./images/lcc_bg_final.png)';
            document.body.style.backgroundSize = "100% auto"; 
            document.body.style.backgroundRepeat = "no-repeat";  
        };


        switch (getPathname) {
            case "/Traveltoucans":
            case "/traveltoucans":
 

                return (
                    <div className="App">
                        <div><h1>NFT Launch Platform</h1>
                            <p>powered by doq</p>
                        </div>
                    </div>
                );
                break;

            case "/Pixelfoxes":
            case "/pixelfoxes":
                return (
                    <div className="App">
                        <div><h1>NFT Launch Platform</h1>
                            <p>powered by doq</p>
                        </div>
                    </div>
                    /*<div className="App">
                        <Pixelfoxes></Pixelfoxes>
                    </div>*/
                );
                break;
            case "/Spookyfoxes":
            case "/spookyfoxes":
                return (
                    <div className="App PixelfoxesSpookySeason">
                        <PixelfoxesSpookySeason></PixelfoxesSpookySeason>
                    </div>
                );
                break;
            case "/PunkAsses":
            case "/punkasses":

                updatePunkasses();
                handlePunkasses();

                return (
                    <div className="App PunkAsses">
                        <PunkAsses></PunkAsses>
                    </div>
                );
                break;
            case "/PunkRocks":
            case "/punkrocks":
                return (
                    <div className="App PunkRocks">
                        <PunkRocks></PunkRocks>
                    </div>
                );
                break;
            case "/LadyClownClub":
            case "/ladyclownclub":

            
                updateLccBg();
                handleLccFavicon();

                return (
                    <div className="App SadClownParty">
                        <SadClownParty></SadClownParty>
                    </div>
                );
                break;
            case "/SOS":
            case "/sos":
                return (
                    <div className="App SOS">
                        <SOS></SOS>
                    </div>
                );
                break;
            case "/Minter":
            case "/minter":
                return (
                    <div className="App">
                        <div>
                            <h1>NFT Launchpad</h1>
                            <p>powered by doq</p>
                        </div>
                    </div>
                );
                break;

            case "/":
                return (
                    <div className="App">
                        <div>
                            <h1>NFT Launchpad</h1>
                            <p>powered by doq</p>
                        </div>
                    </div>
                );
                break;

            default:
                return (
                    <div className="App">
                        <div>
                            <h1>NFT Launchpad</h1>
                            <p>powered by doq</p>
                        </div>
                    </div>
                );
                break;
        }
    }
}

export default App;

