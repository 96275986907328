/* eslint-disable */
import { useEffect, useState } from "react";
import {
    connectWallet,
    getCurrentWalletConnected,
    mintSOSNFT,  
    approveSOS
} from "../util/interact_SOS.js";
import Swal from 'sweetalert2'

import ReactBootstrapSlider from 'react-bootstrap-slider';
import "../css/bootstrap.css";
import "../css/bootstrap-slider.css"
 
const SliderDiv = (props) => {
     
    return (
        <div>
            
            <input id="val" type="text"></input>
        </div>
        
        );
};

const SOS = (props) => {
    
    const [walletAddress, setWallet] = useState("");
    const [status, setStatus] = useState("");

    const [name, setName] = useState("");
    const [description, setDescription] = useState("");
    const [url, setURL] = useState("");
    const [mintNumber, setMintNumber] = useState("");

    useEffect(async () => {
        const { address, status } = await getCurrentWalletConnected();

        setWallet(address);

        if (status == "connected") {
            setStatus(<p>
                {" "}
                {" "}

                <div className="row rowMint">
                    <div className="Minter">
                        <div className="col-9" style={{ float: "left" }}>
                            <ReactBootstrapSlider
                                value={1}
                                change={changeValue}
                            step={1}
                            max={30}
                            min={1}
                            orientation="horizontal" />
                         </div>
                        <div className="col-3" style={{position: "relative", top: "-4px", float: "left" }}>
                            <input id="txtMintNumber" style={{
                                padding:"2px 5px"
                            }} 
                                className="form-control" value="1" />
                        </div>
                    </div>
                </div>
                <button id="mintButton" onClick={onMintPressed}>
                    Mint
                         </button>
                     <p className="mintPrice">10,000,000 $SOS</p>   
            </p>
            );
        } else {
            setStatus(status);

        }

        addWalletListener();
    }, []);

    function addWalletListener() {
        if (window.ethereum) {
            window.ethereum.on("accountsChanged", (accounts) => {
                if (accounts.length > 0) {
                    setWallet(accounts[0]);
                    // setStatus("Write a message in the text-field above.");
                    setStatus(
                        <p>
                            {" "}
                            {" "}
                            <div className="row rowMint">
                                <div className="Minter">
                                    <div className="col-9" style={{ float: "left" }}>
                                        <ReactBootstrapSlider
                                            value={1}
                                            change={changeValue}
                                            step={1}
                                            max={30}
                                            min={1}
                                            orientation="horizontal" />
                                    </div>
                                    <div className="col-3" style={{ position: "relative", top: "-4px", float: "left" }}>
                                        <input id="txtMintNumber" style={{
                                            padding: "2px 5px"
                                        }}
                                            className="form-control" value="1" />
                                    </div>
                                </div>
                            </div>
                            <button id="mintButton" onClick={onMintPressed}>
                                Mint
                       </button>
                     <p className="mintPrice">10,000,000 $SOS</p>   
                        </p>
                    );
                } else {
                    setWallet("");
                    setStatus("Connect to Metamask using the top right button.");
                }
            });
        } else {
            setStatus(
                <p>
                    {" "}
                    🦊{" "}
                    <a target="_blank" href={`https://metamask.io/download.html`}>
                        You must install Metamask, a virtual Ethereum wallet, in your
                        browser.
          </a>
                </p>
            );
        }
    }

    function  changeValue(obj) {
         document.getElementById("txtMintNumber").value = obj.target.value;
    }
    const gobackSOS = async () => {

        document.location = "https://sosclub.io";
    }

    const connectWalletPressed = async () => {

        const walletResponse = await connectWallet();

        if (walletResponse.status == "connected") {
            setStatus(<p>
                {" "}
                {" "}
                <div className="row rowMint">
                    <div className="Minter">
                        <div className="col-9" style={{ float: "left" }}>
                            <ReactBootstrapSlider
                                value={1}
                                change={changeValue}
                                step={1}
                                max={30}
                                min={1}
                                orientation="horizontal" />
                        </div>
                        <div className="col-3" style={{ position: "relative", top: "-4px", float: "left" }}>
                            <input id="txtMintNumber" style={{
                                padding: "2px 5px"
                            }}
                                className="form-control" value="1" />
                        </div>
                    </div>
                </div>
                <button id="mintButton" onClick={onMintPressed}>
                    Mint
            </button>
                     <p className="mintPrice">10,000,000 $SOS</p>   
            </p>
            );
           
            
        } else {
            setStatus(walletResponse.status);

        }

        // setStatus(walletResponse.status);
        setWallet(walletResponse.address);



    };

    const onMintPressed = async () => {
        // console.log("pre mint nft");
        const mintNumber= document.getElementById("txtMintNumber").value;
        setMintNumber(mintNumber);

        //console.log(mintNumber)
        setStatus(<p>
            {" "}
            <section id="slideMint" >
                <div className="row rowMint">
                    <div className="Minter">
                        <div className="col-9" style={{ float: "left" }}>
                            <ReactBootstrapSlider
                                value={1}
                                change={changeValue}
                                step={1}
                                max={30}
                                min={1}
                                orientation="horizontal" />
                        </div>
                        <div className="col-3" style={{ position: "relative", top: "-4px", float: "left" }}>
                            <input id="txtMintNumber" style={{
                                padding: "2px 5px"
                            }}
                                className="form-control" value="1" />
                        </div>
                    </div>
                </div>
                <button id="mintButton" >
                        Approving, please wait...
                 </button>
            </section>
        </p>
        );
        const { success_approve, status_approve} = await approveSOS(mintNumber);
        
        if (!success_approve) {
            Swal.fire({
                icon: 'error',
                title: 'Oops...',
                text: status_approve
            })
            setStatus(<p>
                {" "}
                <section id="slideMint" >
                    <div className="row rowMint">
                        <div className="Minter">
                            <div className="col-9" style={{ float: "left" }}>
                                <ReactBootstrapSlider
                                    value={1}
                                    change={changeValue}
                                    step={1}
                                    max={30}
                                    min={1}
                                    orientation="horizontal" />
                            </div>
                            <div className="col-3" style={{ position: "relative", top: "-4px", float: "left" }}>
                                <input id="txtMintNumber" style={{
                                    padding: "2px 5px"
                                }}
                                    className="form-control" value="1" />
                            </div>
                        </div>
                    </div>
                    <button id="mintButton" onClick={onMintPressed}>
                        Mint
                    </button>
                     <p className="mintPrice">10,000,000 $SOS</p>   
                </section>
            </p>
            );
        }else{
 
            const { success, status } = await mintSOSNFT(url, name, description, mintNumber);
            // setStatus(status);
            //console.log(success);
            //console.log("call swal");

            if (!success) {
                Swal.fire({
                    icon: 'error',
                    title: 'Oops...',
                    text: status
                })
            } else {
                Swal.fire({
                    icon: 'success',
                    title: 'Success!',
                    text: status
                })

            }
            setStatus(<p>
                {" "}
                <section id="slideMint" >
                    <div className="row rowMint">
                        <div className="Minter">
                            <div className="col-9" style={{ float: "left" }}>
                                <ReactBootstrapSlider
                                    value={1}
                                    change={changeValue}
                                    step={1}
                                    max={30}
                                    min={1}
                                    orientation="horizontal" />
                            </div>
                            <div className="col-3" style={{ position: "relative", top: "-4px", float: "left" }}>
                                <input id="txtMintNumber" style={{
                                    padding: "2px 5px"
                                }}
                                    className="form-control" value="1" />
                            </div>
                        </div>
                    </div>
                    <button id="mintButton" onClick={onMintPressed}>
                        Mint
                    </button>
                     <p className="mintPrice">10,000,000 $SOS</p>   
                        
                </section>
            </p>
            );
        }
        //if (success) {
        //  setName("");
        //  setDescription("");
        //  setURL("");
        // }
    };
    //color scheme: #aaf8fe
    return (
       
        
        <div data-v-app="" id="app">
            
            <div id="SOS"> 
            
                <div style={{ padding: '10px'  }}>
                    <button  style={{ margin: '10px',float:'left'  }} id="backButtonSOS" onClick={gobackSOS}><span>Back</span></button>
                </div>  
                            <img id="mint-img" src="./images/mint-sos.png"  />
                <section id="slide" >
                   
                  
                        <div className="Minter">
                        <div className="row rowMint">
                             <img id="mint-logo" src="./images/mint-sos-logo.png"   /> 
                        </div>
                        
                            <form>  </form>
              
                        </div>
                     
                        <button id="walletButton" onClick={connectWalletPressed}>
                            {walletAddress.length > 0 ? (
                                "Connected: " +
                                String(walletAddress).substring(0, 6) +
                                "..." +
                                String(walletAddress).substring(38)
                            ) : (
                                <span>Connect Wallet</span>
                            )}
                        </button>  
                    
                    
                        <p id="status" style={{ width: '100%', color: "red" }}>
                            {status}
                        </p>
                     
                </section>
            </div>
               
        </div>
        
    );
};

export default SOS;
