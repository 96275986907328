/* eslint-disable */
import { useEffect, useState } from "react";
import {
    connectWallet,
    getCurrentWalletConnected,
    mintPunkAssesNFT,  
} from "../util/interact_PunkAsses.js";
import Swal from 'sweetalert2'

import ReactBootstrapSlider from 'react-bootstrap-slider';
import "../css/bootstrap.css";
import "../css/bootstrap-slider.css"
 
const SliderDiv = (props) => {
     
    return (
        <div>
            
            <input id="val" type="text"></input>
        </div>
        
        );
};

const PunkAsses = (props) => {
    
    const [walletAddress, setWallet] = useState("");
    const [status, setStatus] = useState("");

    const [name, setName] = useState("");
    const [description, setDescription] = useState("");
    const [url, setURL] = useState("");
    const [mintNumber, setMintNumber] = useState("");

    useEffect(async () => {
        const { address, status } = await getCurrentWalletConnected();

        setWallet(address);

        if (status == "connected") {
            setStatus(<p>
                {" "}
                {" "}

                <div className="row rowMint">
                    <div className="Minter">
                        <div className="col-9" style={{ float: "left" }}>
                            <ReactBootstrapSlider
                                value={1}
                                change={changeValue}
                            step={1}
                            max={30}
                            min={1}
                            orientation="horizontal" />
                         </div>
                        <div className="col-3" style={{position: "relative", top: "-4px", float: "left" }}>
                            <input id="txtMintNumber" style={{
                                padding:"2px 5px"
                            }} 
                                className="form-control" value="1" />
                        </div>
                    </div>
                </div>
                <button id="mintButton" onClick={onMintPressed}>
                    Mint
                         </button>
            </p>
            );
        } else {
            setStatus(status);

        }

        addWalletListener();
    }, []);

    function addWalletListener() {
        if (window.ethereum) {
            window.ethereum.on("accountsChanged", (accounts) => {
                if (accounts.length > 0) {
                    setWallet(accounts[0]);
                    // setStatus("Write a message in the text-field above.");
                    setStatus(
                        <p>
                            {" "}
                            {" "}
                            <div className="row rowMint">
                                <div className="Minter">
                                    <div className="col-9" style={{ float: "left" }}>
                                        <ReactBootstrapSlider
                                            value={1}
                                            change={changeValue}
                                            step={1}
                                            max={30}
                                            min={1}
                                            orientation="horizontal" />
                                    </div>
                                    <div className="col-3" style={{ position: "relative", top: "-4px", float: "left" }}>
                                        <input id="txtMintNumber" style={{
                                            padding: "2px 5px"
                                        }}
                                            className="form-control" value="1" />
                                    </div>
                                </div>
                            </div>
                            <button id="mintButton" onClick={onMintPressed}>
                                Mint
                       </button>
                        </p>
                    );
                } else {
                    setWallet("");
                    setStatus("Connect to Metamask using the top right button.");
                }
            });
        } else {
            setStatus(
                <p>
                    {" "}
                    🦊{" "}
                    <a target="_blank" href={`https://metamask.app.link/dapp/576labs.com/punkasses`}>
                        You must install Metamask, a virtual Ethereum wallet, in your
                        browser.
          </a>
                </p>
            );
        }
    }

    function  changeValue(obj) {
         document.getElementById("txtMintNumber").value = obj.target.value;
    }
    const gobackPunkAsses = async () => {

        document.location = "https://punkasses.io";
    }

    const connectWalletPressed = async () => {

        const walletResponse = await connectWallet();

        if (walletResponse.status == "connected") {
            setStatus(<p>
                {" "}
                {" "}
                <div className="row rowMint">
                    <div className="Minter">
                        <div className="col-9" style={{ float: "left" }}>
                            <ReactBootstrapSlider
                                value={1}
                                change={changeValue}
                                step={1}
                                max={30}
                                min={1}
                                orientation="horizontal" />
                        </div>
                        <div className="col-3" style={{ position: "relative", top: "-4px", float: "left" }}>
                            <input id="txtMintNumber" style={{
                                padding: "2px 5px"
                            }}
                                className="form-control" value="1" />
                        </div>
                    </div>
                </div>
                <button id="mintButton" onClick={onMintPressed}>
                    Mint
            </button>
            </p>
            );
           
            
        } else {
            setStatus(walletResponse.status);

        }

        // setStatus(walletResponse.status);
        setWallet(walletResponse.address);



    };

    const onMintPressed = async () => {
        // console.log("pre mint nft");
        const mintNumber= document.getElementById("txtMintNumber").value;
        setMintNumber(mintNumber);

        //console.log(mintNumber)

        const { success, status } = await mintPunkAssesNFT(url, name, description, mintNumber);
        // setStatus(status);
        //console.log(success);
        //console.log("call swal");

        if (!success) {
            Swal.fire({
                icon: 'error',
                title: 'Oops...',
                text: status
            })
        } else {
            Swal.fire({
                icon: 'success',
                title: 'Success!',
                text: status
            })

        }
        setStatus(<p>
            {" "}
            <section id="slideMint" >
                <div className="row rowMint">
                    <div className="Minter">
                        <div className="col-9" style={{ float: "left" }}>
                            <ReactBootstrapSlider
                                value={1}
                                change={changeValue}
                                step={1}
                                max={30}
                                min={1}
                                orientation="horizontal" />
                        </div>
                        <div className="col-3" style={{ position: "relative", top: "-4px", float: "left" }}>
                            <input id="txtMintNumber" style={{
                                padding: "2px 5px"
                            }}
                                className="form-control" value="1" />
                        </div>
                    </div>
                </div>
                <button id="mintButton" onClick={onMintPressed}>
                    Mint
                </button>
                    
            </section>
        </p>
        );
        //if (success) {
        //  setName("");
        //  setDescription("");
        //  setURL("");
        // }
    };
    //color scheme: #aaf8fe
    //<button id="backButtonPunkAsses" onClick={gobackPunkAsses}><span>Back</span></button>
    return (
       
        
        <div data-v-app="" id="app">
            
            <div id="PunkAsses">
                <div style={{widht:'100%', background:'#fff',height: '75px' }}>
                
                <img src="./images/punkasslogo.png" style={{ position:'relative',float:'left', width: '227px', padding:'10px', marginLeft:'20px' }} />

                </div> 
                <br/><br/>
                <img src="./images/punkasses.jpeg" style={{ position:'relative', width: '350px' }} />
                   
                <section id="slide" >
                    <div className="row rowMint">

                        <div className="Minter">
                            
                            <button id="walletButton" onClick={connectWalletPressed}>
                                {walletAddress.length > 0 ? (
                                    "Connected: " +
                                    String(walletAddress).substring(0, 6) +
                                    "..." +
                                    String(walletAddress).substring(38)
                                ) : (
                                    <span>Connect Wallet</span>
                                )}
                            </button>

                            <form>  </form>

                        </div>
                    </div>
                    <div className="row rowMint">
                        <p id="status" style={{ width: '100%', color: "red" }}>
                            {status}
                        </p>
                    </div>
                </section>
            </div>
        </div>
    );
};

export default PunkAsses;
