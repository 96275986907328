/* eslint-disable */

import { pinFileToIPFS } from "./pinata.js";
require("dotenv").config();
const alchemyKey = process.env.REACT_APP_ALCHEMY_KEY_PFSS;
const publicKey = process.env.REACT_APP_PUBLIC_KEY_PFSS; 
const baseURL = process.env.REACT_APP_BASE_URL;
const uploadPath = process.env.REACT_APP_UPLOAD_PATH;
const pkey = process.env.REACT_APP_PINATA_KEY;
const psecret = process.env.REACT_APP_PINATA_SECRET;
const jsonPath = process.env.REACT_APP_UPLOAD_JSON_PATH;
const nftName = process.env.REACT_APP_NFT_NAME;
const nftDescription = process.env.REACT_APP_NFT_DESCRIPTION;
const nftFolderName = process.env.REACT_APP_NFT_FOLDERNAME;
const nftTotalSupply = process.env.REACT_APP_NFT_TOTAL_SUPPLY;
 
const FormData = require('form-data');
const contractABI = require("../contract-abi-pfss.json");
const contractABIOG = require("../contract-abi-pf.json");

const alchemyKeyPF = process.env.REACT_APP_ALCHEMY_KEY_PF;
const alchemyKeyPFSS = process.env.REACT_APP_ALCHEMY_KEY_PFSS;
const publicKeyPFSS = process.env.REACT_APP_PUBLIC_KEY_PFSS;
const publicKeyPFOGCollection = process.env.REACT_APP_PUBLIC_KEY_PF; 

const fs = require('fs')
const jquery = require('jquery')
 
const contractAddress = publicKeyPFSS; //rinkey -ss

////////////////////////
////////////////////////
//Pixel Foxes OG Collection
////////////////////////
////////////////////////
const contractAddressPixelFoxesOGCollection = publicKeyPFOGCollection; //production -ss


const { createAlchemyWeb3 } = require("@alch/alchemy-web3");
const web3 = createAlchemyWeb3(alchemyKey);

const web3PFOG = createAlchemyWeb3(alchemyKeyPF);

export const getOwnedTokensOFCollection = async() => {

    var tokenInst = await new web3PFOG.eth.Contract(contractABIOG, contractAddressPixelFoxesOGCollection);
    
    var balance = await tokenInst.methods.balanceOf(window.ethereum.selectedAddress).call();

    return balance;

    /*tokenInst.methods.balanceOf(window.ethereum.selectedAddress).call().then(function (bal) {

       console.log(bal);

       if (parseInt(bal) > 0) {

           return {
               message: "Welcome to the Fox Den! Thank you for being a part of the family. ",
               statusog: "owned",
           };

       } else {
           return {
               message: "We're sorry. This claim is for only the fox family.  ",
               statusog: "",
           };

       }
       for (var i = 0; i < parseInt(bal); i++) {
           tokenInst.tokenOfOwnerByIndex.call(window.ethereum.selectedAddress, i)
           .then((id) => { 
                
               console.log("I own token ID:" + id.tostring())
               return {
                   message_og: "Welcome to the Fox Den! Thank you for being a part of the family. ",
                   status_og: "owned",
               };

           });       
       }   
      
      

 
    })

*/

   
    /*for(var i = 0; i < balance.toNumber(); i++) {
        myERC721Token.tokenOfOwnerByIndex.call(web3.eth.accounts[0], i)
        .then((id) => { ... });       
    }   
    */

}

export const getOwnedTokensSSCollection = async () => {

    var tokenInst = await new web3.eth.Contract(contractABI, contractAddress);

    var balance = await tokenInst.methods.balanceOf(window.ethereum.selectedAddress).call();

    return balance;
 
}

export const connectWallet = async () => {
    if (window.ethereum) {

         //const networkId = await web3.eth.net.getId();
        //const networkType = await web3.eth.net.getNetworkType();

       // console.log(networkId)
       // console.log(networkType)

    try {
      const addressArray = await window.ethereum.request({
        method: "eth_requestAccounts",
      });
      const obj = {
         // status: "👆🏽 Write a message in the text-field above.",
          status: "connected",
        address: addressArray[0],
      };
      return obj;
    } catch (err) {
      return {
        address: "",
        status: "😥 " + err.message,
      };
    }
  } else {
    return {
      address: "",
      status: (
        <span>
          <p>
            {" "}
            🦊{" "}
            <a target="_blank" href={'https://metamask.io/download.html'}>
              You must install Metamask, a virtual Ethereum wallet, in your
              browser.
            </a>
          </p>
        </span>
      ),
    };
  }
};

export const getCurrentWalletConnected = async () => {
    if (window.ethereum) {

        const networkId = await web3.eth.net.getId();
        const networkType = await web3.eth.net.getNetworkType();

        //console.log(networkId)
        //console.log(networkType)
    try {
      const addressArray = await window.ethereum.request({
          method: "eth_accounts", getOwnedTokensOFCollection
      });
        if (addressArray.length > 0) {

            //showm mint button here

        return {
          address: addressArray[0],
          status:"connected",
        };
      } else {
        return {
          address: "",
          status: "Connect to Metamask using the top right button.",
        };
      }
    } catch (err) {
      return {
        address: "",
        status: "😥 " + err.message,
      };
    }
  } else {
    return {
      address: "",
      status: (
        <span>
          <p>
            {" "}
            🦊{" "}
            <a target="_blank" href={`https://metamask.io/download.html`}>
              You must install Metamask, a virtual Ethereum wallet, in your
              browser.
            </a>
          </p>
        </span>
      ),
    };
  }
};
  
export const getTotalContractSupply = async () => {

    window.contract = await new web3.eth.Contract(contractABI, contractAddress);
    const nftContract = window.contract; 
    const sTotalSupply = await nftContract.methods.totalSupply().call()
    const nNftTotalSupply = parseInt(nftTotalSupply);
   
    return {
        TotalSupply: nNftTotalSupply 
    };
}

const getSignatureData = async() => {

    const axios = require('axios');

    let res = await axios.post('https://tokentoucan.com/api/getSignature', {
                    account: window.ethereum.selectedAddress
                })
 

    return res.data
}


//clean code function
export const mintPixelFoxesNFT = async (url, name, description) => {
    
    ///////
    const nMintNumber = 1;
    
    window.contract = await new web3.eth.Contract(contractABI, contractAddress);

    const nftContractPF = window.contract;

    const blnPausedPF = await nftContractPF.methods._paused.call().call();

    if (blnPausedPF) {
        console.log("return");
        return {
            success: false,
            status: "Minting not active.",
        };
    }

    
    const sTotalSupply = await nftContractPF.methods.totalSupply().call()

    // const nNftTotalSupply = parseInt(nftTotalSupply);

    //if (sTotalSupply >= nNftTotalSupply) {
    if (sTotalSupply >= 1000) {
        return {
            success: false,
            status: "All Spooky Foxes minted!",
        };
    }

    const res = await getSignatureData();

    if (res.success == false) {
        return {
            success: false,
            status: "Signature is not valid.",
        };
    }

    const hash = res.hash;
    const signature = res.signature;
    const nonce = res.nonce;
    const ret_msg = res.message;
     
    ////console.log(hash);
    ///console.log(signature);
    //console.log(nonce);
    ///console.log(ret_msg);
     
    const sPrice = await nftContractPF.methods.getPrice().call()
    const amountToSend = 0//FREE eth
    //const nonce = await web3.eth.getTransactionCount(window.ethereum.selectedAddress, 'latest'); //get latest nonce
    const ethAddress = window.ethereum.selectedAddress;
    const sGasPrice = web3.utils.toHex(await web3.eth.getGasPrice());

    const sMintPrice = (nMintNumber * 0.0)
    //the transaction
    const tx = {
        from: ethAddress,
        to: contractAddress,
        gasPrice: sGasPrice,
        value: web3.utils.toHex(web3.utils.toWei(String(sMintPrice), 'ether')),
        data: nftContractPF.methods.SpookyMint(signature, nonce).encodeABI()
    };
     
    try {

        const txHash = await window.ethereum.request({
            method: "eth_sendTransaction",
            params: [tx],
        });
         
        return {
            success: true,
            status:
            "✅ Successful transaction. Check on Etherscan: https://etherscan.io/tx/" +
            txHash,
        };

    } catch (error) {

        return {
            success: false,
            status: "Something went wrong: " + error.message,
        };

    }

    
    return {
        success: false,
        status: "😥 Something went wrong: ",
    };
};
