/* eslint-disable */ 
import { pinFileToIPFS } from "./pinata.js";
require("dotenv").config();

const fs = require('fs')
const jquery = require('jquery')
 
const alchemyKey = process.env.REACT_APP_ALCHEMY_KEY_PAMATIC;
const publicKey = process.env.REACT_APP_PUBLIC_KEY_PAMATIC; 
const baseURL = process.env.REACT_APP_BASE_URL_PAMATIC;
const uploadPath = process.env.REACT_APP_UPLOAD_PATH_PAMATIC;
const pkey = process.env.REACT_APP_PINATA_KEY_PAMATIC;
const psecret = process.env.REACT_APP_PINATA_SECRET_PAMATIC;
const jsonPath = process.env.REACT_APP_UPLOAD_JSON_PATH_PAMATIC;
const nftName = process.env.REACT_APP_NFT_NAME_PAMATIC;
const nftDescription = process.env.REACT_APP_NFT_DESCRIPTION_PAMATIC;
const nftFolderName = process.env.REACT_APP_NFT_FOLDERNAME_PAMATIC;
const nftTotalSupply = process.env.REACT_APP_NFT_TOTAL_SUPPLY_PAMATIC;
 
const FormData = require('form-data');
const contractABI = require("../contract-abi-matic-pa.json");

const alchemyKeyPF = process.env.REACT_APP_ALCHEMY_KEY_PAMATIC;
const publicKeyPF = process.env.REACT_APP_PUBLIC_KEY_PAMATIC;
const contractAddress = process.env.REACT_APP_PUBLIC_KEY_PAMATIC; 
const contractPunkAssesMintPrice = process.env.REACT_APP_MINT_PRICE_PAMATIC; //price
 
const { createAlchemyWeb3 } = require("@alch/alchemy-web3");
const web3 = createAlchemyWeb3(alchemyKey);
const Web3 = require("web3");
 
export const connectWallet = async () => {
    if (window.ethereum) {
         
        try {
          const addressArray = await window.ethereum.request({
            method: "eth_requestAccounts",
          });
          const obj = {
             // status: "👆🏽 Write a message in the text-field above.",
              status: "connected",
            address: addressArray[0],
          };
          return obj;
        } catch (err) {
          return {
            address: "",
            status: "😥 " + err.message,
          };
        }
      } else {
        return {
          address: "",
          status: (
            <span>
              <p>
                {" "}
                🦊{" "}
                <a target="_blank" href={'https://metamask.io/download.html'}>
                  You must install Metamask, a virtual Ethereum wallet, in your
                  browser.
                </a>
              </p>
            </span>
          ),
        };
      }
};

export const getCurrentWalletConnected = async () => {
  if (window.ethereum) {

        //const networkId = await web3.eth.net.getId();
        //const networkType = await web3.eth.net.getNetworkType();

        //console.log(networkId)
        //console.log(networkType)
    try {
      const addressArray = await window.ethereum.request({
        method: "eth_accounts",
      });
        if (addressArray.length > 0) {

            //showm mint button here

        return {
          address: addressArray[0],
          status:"connected",
        };
      } else {
        return {
          address: "",
          status: "Connect to Metamask using the top right button.",
        };
      }
    } catch (err) {
      return {
        address: "",
        status: "😥 " + err.message,
      };
    }
  } else {
    return {
      address: "",
      status: (
        <span>
          <p>
            {" "}
            🦊{" "}
            <a target="_blank" href={`https://metamask.io/download.html`}>
              You must install Metamask, a virtual Ethereum wallet, in your
              browser.
            </a>
          </p>
        </span>
      ),
    };
  }
};
 
export const getNetworkStatus = async() => {
  const ethereum = await web3Instance();
  if (!ethereum) return -1;
  const chainId = await ethereum.eth.getChainId();
 //console.log(chainId);
  return chainId;
};

const web3Instance = () => {
  if (window.ethereum) {
      return new Web3(window.ethereum);
  } else if (window.web3) {
      return new Web3(window.currentProvider);
  }
};
  
//clean code function
export const mintPunkAssesNFT = async (url, name, description, mintNumber) => {
 
    const network = await getNetworkStatus()
        
    if (network != 1){
      return {
        success: false,
        status: "Wrong Network. Please connect to Ethereum mainnet to continue.",
      };  
    }

    const nMintNumber = mintNumber;
    
    if (nMintNumber > 30) { 
        return {
            success: false,
            status: "Mint cannot exceed 30",
        }; 
    }
    
    window.contract = await new web3.eth.Contract(contractABI, contractAddress);

    const nftContractPF = window.contract;
    const blnPausedPF = await nftContractPF.methods._paused.call().call();
    
    if (blnPausedPF) {
        return {
            success: false,
            status: "Minting not active.",
        };
    }
    
    const sTotalSupply = await nftContractPF.methods.totalSupply().call()
    
    //const nNftTotalSupply = parseInt(nftTotalSupply); 
    //if (sTotalSupply >= nNftTotalSupply) {

    if (sTotalSupply >= 10000) {
        return {
            success: false,
            status: "Mint Sold Out!",
        };
    }
 
    //const sPrice = await nftContractPF.methods.getPrice().call()
    //const amountToSend = 42000000000000000  // 10000000000000000;.01 eth
    const nonce = await web3.eth.getTransactionCount(window.ethereum.selectedAddress, 'latest'); //get latest nonce
    const ethAddress = window.ethereum.selectedAddress;
    const sMintPrice = (nMintNumber * contractPunkAssesMintPrice) //(nMintNumber * 0.042)
   
    try {


        var esGas;
        esGas = await web3.eth.estimateGas({
          from: ethAddress,
          to: contractAddress,     
          nonce: nonce,        
          value: web3.utils.toHex(web3.utils.toWei(String(sMintPrice), 'ether')),
          data: nftContractPF.methods.PunkAssMint(web3.utils.toBN(nMintNumber)).encodeABI()
        });
      
          //const sGasPrice = web3.utils.toHex(await web3.eth.getGasPrice());
          const sGasPrice = web3.utils.toHex(esGas);

          //the transaction
          const tx = {
              from: ethAddress,
              to: contractAddress,
              //gasPrice: sGasPrice,
              gas: sGasPrice,
              value: web3.utils.toHex(web3.utils.toWei(String(sMintPrice), 'ether')),
              data: nftContractPF.methods.PunkAssMint(web3.utils.toBN(nMintNumber)).encodeABI()
          };

   

          const txHash = await window.ethereum.request({
              method: "eth_sendTransaction",
              params: [tx],
          });

          return {
              success: true,
              status:
              "✅ Successful transaction. Check on Etherscan: https://etherscan.io/tx/" +
              txHash,
          };

      } catch (error) {

          return {
              success: false,
              status: "Something went wrong: " + error.message,
          };

      } 
};
